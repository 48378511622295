<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  pl-4 pr-4 pt-4 pb-2 gap-2 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input v-model="filters.fechaInicial"  class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div class="field">
        <label for="icon">Fecha final</label>
        <input v-model="filters.fechaFinal" class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div style="align-self: center;" class="field">
        <label for="nombre">Proveedor</label>
        <div>
          <Dropdown
            v-model="filters.proveedor"
            :options="proveedores"
            @click="$h.xxsInput"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            panelClass="text-xxs"
            optionLabel="CardName"
            :showClear="true"
            optionValue="CardCode"
            :filter="true"
            placeholder="Seleccione un proveedor" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 pl-4 pb-6 gap-4 pr-4 lg:mb-0">
      <div class="field grid">
        <label>Número pedido</label>
        <Chips
          id="numPedidos"
          v-model="filters.numPedido"
          :allowDuplicate="false"
          separator=","
          inputClass="p-inputtext-sm"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Pedidos separados por ,"
        />
      </div>
      <div class="field grid">
        <label>Número de oferta</label>
        <Chips
          id="numOfertas"
          v-model="filters.numOfertas"
          :allowDuplicate="false"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Ofertas separados por ,"
        />
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pb-4">
      <div class="pb-2 flex">
        <Button class="border-0" @click="searchFilters" label="Buscar"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" @click="clearFilters" label="Limpiar" />
      </div>
    </div>
  </section>
  <div class="pt-6">
    <DataTable
      :value="pedidosList"
      class="p-datatable-xs text-xs"
      dataKey="DocNum"
      filterDisplay="menu"
      responsiveLayout="scroll"
      v-model:selection="selectedCustomers"
    >
      <template #empty>
        No existen pedidos con los criterios de búsqueda
      </template>
      <Column
        v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.copiar-a-recepcion')"
        selectionMode="multiple"
        bodyStyle="text-align: center;justify-content: center;"
        headerStyle="text-align: center;justify-content: center;"
        style="min-width: 3rem; max-width: 3rem"
      />
      <Column field="fecha" bodyStyle="text-align: center;justify-content: center;" headerStyle="text-align: center;justify-content: center;" header="N° Pedido" style="min-width: 10rem">
        <template #body="{data}">
          <div class="lg:text-xs">
            <Button :label="`${data.DocNum}`"
                    class="p-button-link text-black"
                    style="font-size: 0.75rem"
                    @click="openModalDetails(data.DocNum)"
            />
          </div>
        </template>
      </Column>
      <Column field="CantidadMds" header="Cant. de medicamentos" headerStyle="text-align: center;justify-content: center;" bodyStyle="text-align: center;justify-content: center;" style="min-width: 8rem"/>
      <Column field="cantidadFaltante" header="Cant. sin gestionar" headerStyle="text-align: center;justify-content: center;" bodyStyle="text-align: center;justify-content: center;" style="min-width: 8rem"/>
      <Column field="fecha" bodyStyle="text-align: center;justify-content: center;" headerStyle="text-align: center;justify-content: center;" header="Fecha Pedido" style="min-width: 8rem">
        <template #body="{data}">
          <div class="lg:text-xs">
              {{ data.DocDate }}
          </div>
        </template>
      </Column>
      <Column field="proveedor" bodyStyle="text-align: center;justify-content: center;" headerStyle="text-align: center;justify-content: center;" header="Proveedor" style="min-width: 25rem">
        <template #body="{data}">
          <div class="lg:text-xs">
            <p>
              {{ data.CardName }}
            </p>
          </div>
        </template>
      </Column>
      <Column field="" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="Prioridad Recepción"
              style="min-width: 25rem">
        <template #body="{data}">
          <div v-if="data.rangoRecepcion === null" class="lg:text-xs">
            <Badge value="Sin Configuración" style="cursor: pointer;" class="bg-red-200 text-red-800 text-xxs h-4 capitalize" @click="onConfig"></Badge>
          </div>
          <div v-if="data.rangoRecepcion >= 0 && data.rangoRecepcion <= 23 && data.rangoRecepcion !== null" class="lg:text-xs">
            <Badge :value="`${data.rangoRecepcion}  Horas restantes `" class="bg-yellow-200 text-yellow-800 text-xxs h-4"></Badge>
          </div>
          <div v-if="data.rangoRecepcion >= 24" class="lg:text-xs">
            <Badge :value="data.tiempoRestante" class="bg-green-300 text-green-800 text-xxs h-4"></Badge>
          </div>
          <div v-if="data.rangoRecepcion < 0" class="lg:text-xs">
            <Badge :value="data.tiempoRestante" class="bg-red-200 text-red-800 text-xxs h-4"></Badge>
          </div>
        </template>
      </Column>
      <Column field="" bodyStyle="text-align: center;justify-content: center;" headerStyle="text-align: center;justify-content: center;" header="Acciones" style="min-width: 15rem">
        <template #body="{data}">
          <div class="lg:text-xs flex gap-4 justify-center">
            <Button icon="pi pi-print" v-tooltip.top="'Imprimir Pedido'" class="p-button-rounded p-button-xs p-button-info" @click="printPdf(data.DocNum)"/>
            <Button
              icon="pi pi-times"
              v-tooltip.top="'Cancelar Pedido'"
              class="p-button-rounded p-button-xs p-button-danger"
              @click="cancelOrder(data)"
              v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.cancelar-pedido') && data.CantidadMds === data.cantidadFaltante"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="offset"
       :rows="limit"
       :totalRecords="totalRecords"
       :rowsPerPageOptions="[1,10,20,30,50]"
       :pageLinkSize="pageLinkSize"
       @page="onPage($event)"
    />
  </div>
  <Card>
    <template #content>
      <div class="pt-2 flex justify-end">
        <Button
          v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.copiar-a-recepcion')"
          label="Copiar a recepción"
          icon="pi pi-copy"
          class="p-button-raised p-button-success floating-button p-button-rounded"
          @click="onCreate"
        />
      </div>
    </template>
  </Card>
  <div>
    <modalDetails ref="openModalDet" @refresh="getDataLotes"/>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, defineAsyncComponent } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import recepcionPedidos from '@/apps/pharmasan/compras/logistica/store/recepcionPedidos'
import modalDetails from './modalDetails.vue'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'
export default {
  name: 'listarPedidos',
  components: {
    modalDetails: defineAsyncComponent(() =>
      import('./modalDetails.vue')
    )
  },
  setup () {
    const store = useStore()
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const router = useRouter()
    const openModalDet = ref()
    const selectedCustomers = ref()
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const pedidosList = ref([])
    const filters = reactive({
      proveedor: null,
      numPedido: [],
      numOfertas: [],
      fechaFinal: null,
      fechaInicial: null
    })
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        numPedido: filters.numPedido,
        numOfertas: filters.numOfertas,
        proveedor: filters.proveedor
      }
    })
    const openModalDetails = (DocNum) => {
      _RecepcionPedidosService.getDetails(DocNum).then(({ data }) => {
        openModalDet.value.openModal(data)
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginate()
    }
    const getDataLotes = async (DocEntryPedido) => {
      await getPaginate()
      const findIndex = pedidosList.value.findIndex((a) => a.DocEntry === DocEntryPedido)
      openModalDetails(pedidosList.value[findIndex].DocNum)
    }
    const searchFilters = () => {
      getPaginate()
    }
    const clearFilters = () => {
      filters.proveedor = null
      filters.numPedido = []
      filters.numOfertas = []
      getPaginate()
    }
    const proveedores = ref([])
    const getPaginate = async () => {
      selectedCustomers.value = []
      await _RecepcionPedidosService.paginate(params.value).then(({ data }) => {
        pedidosList.value = data.data
        totalRecords.value = data.rows[0].countRows
      })
      pedidosList.value.forEach((element) => {
        let stringTiempo = ''
        if (element.rangoRecepcion >= 24 || element.rangoRecepcion < 0) {
          const dias = Math.floor(element.rangoRecepcion / 24)
          const horas = element.rangoRecepcion < 0 ? (element.rangoRecepcion % 24 * -1) : element.rangoRecepcion % 24
          stringTiempo = `${dias} día(s) y ${horas} horas restantes`
          element.tiempoRestante = stringTiempo
        }
      })
    }
    const getProveedores = () => {
      _RecepcionPedidosService.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    const printPdf = (DocNum) => {
      _RecepcionPedidosService.imprimirPdfPedido(DocNum)
    }
    const onConfig = () => {
      if (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.configuracion')) {
        router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.configuracion' })
      }
    }
    const onCreate = () => {
      // Si no ha seleccionado ningun pedido mostrarme un mensaje de alerta
      if (selectedCustomers.value.length === 0) {
        Swal.fire({
          title: '¡Atención!',
          text: 'Debe seleccionar al menos un pedido',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        })
      } else
      if (selectedCustomers.value) {
        const temp = selectedCustomers.value[0].CardCode
        let validate = false
        selectedCustomers.value.forEach((x) => {
          if (temp === x.CardCode) {
            validate = true
          } else {
            validate = false
          }
        })
        if (validate) {
            const date = Date.now()
            const payload = {
              CardCode: selectedCustomers.value[0].CardCode,
              CardName: selectedCustomers.value[0].CardName,
              TaxDate: selectedCustomers.value[0].TaxDate,
              DocDate: selectedCustomers.value[0].DocDate,
              pedidos: [...new Set(selectedCustomers.value.map((d) => d.DocNum))],
              ExpDate: dayjs(date).format('YYYY-MM-DD')
            }
            recepcionPedidos.commit('onSetEncabezado', payload)
            router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-recepcion' })
          } else {
            Swal.fire({
              icon: 'info',
              text: 'Solo se pueden seleccionar pedidos correspondientes a un mismo proveedor.',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true
            })
          }
        } else {
        Swal.fire({
          icon: 'info',
          text: 'Debes seleccionar al menos 1 pedido para procesar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    }
    const verificarPermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const cancelOrder = ({ DocEntry, DocNum }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro?',
        text: 'La cancelación del pedido es irreversible',
        focusCancel: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(({ value }) => {
        if (value) {
          _RecepcionPedidosService.cancelarPedido(DocEntry).then(({ data }) => {
            if (data.message) {
              Swal.fire({
                icon: 'success',
                title: `Se cancelo el pedido N. ${DocNum}`,
                text: data.message
              }).then(() => {
                getPaginate()
              })
            }
          })
        }
      })
    }
    onMounted(() => {
      const date = new Date()
      const now = new Date()
      const lastMonth = date.setMonth(date.getMonth() - 1)
      filters.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
      filters.fechaFinal = dayjs(now).format('YYYY-MM-DD')
      getPaginate()
      getProveedores()
    })
    return {
      pedidosList,
      selectedCustomers,
      filters,
      proveedores,
      getPaginate,
      getProveedores,
      params,
      onPage,
      page,
      offset,
      limit,
      pageLinkSize,
      totalRecords,
      searchFilters,
      clearFilters,
      openModalDetails,
      modalDetails,
      openModalDet,
      onCreate,
      printPdf,
      cancelOrder,
      getDataLotes,
      onConfig,
      verificarPermiso,
      store
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-multiselect-label ) {
  display: flex !important;
  flex-wrap: wrap !important;
}
.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}
::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}
::v-deep(.p-multiselect-label-container) {
  overflow-y: auto !important;
  max-height: 75px !important;
}
::v-deep(.country-item) {
  margin: 2px !important
}
::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717A;
  width: 100rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
::v-deep(.pi) {
  font-size: 0.8rem;
}
::v-deep(.p-column-header-content) {
  justify-content: center;
}
.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}
</style>
